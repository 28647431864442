<template>
  <main>
    <div class="content-btn-addReturn">
      <router-link style="width: 0px" to="/dashboard">
        <button class="btn-return">
          {{ $t("sectionRegisters.backDashbord") }}
        </button>
      </router-link>
      <router-link
        style="width: 0px"
        :to="{ name: 'addOrUpdate', params: { activeRegister } }"
      >
        <button class="userGroup-btn-add">
          {{ $t("sectionRegisters.addUser") }}
        </button>
      </router-link>
    </div>
    <h1 class="titleViewUserGroup">{{ $t("sectionRegisters.groupUsers") }}</h1>
    <!-- <label class="contentAmountPage">
      <span>{{ $t("sectionRegisters.amountPageTitle") }}</span>
      <input v-model="amountPage" type="text" placeholder="Exemplo: 5">
      <button @click="viewPages">{{ $t("sectionRegisters.confirm") }}</button>
    </label> -->
    <table class="content-tableUserGroup">
      <tr class="header-table">
        <th>Descrição</th>
      </tr>
      <tr
        class="items-table"
        v-for="(user, index) in stateGroupUsers"
        :key="index"
      >
        <td>{{ user.descricao }}</td>
        <span class="content-btn-userGroupEditOrDelete">
          <router-link
            :to="{ name: 'addOrUpdate', params: { activeEdit, user } }"
          >
            <button class="editStyle">
              {{ $t("sectionRegisters.edit") }}
            </button>
          </router-link>
          <button class="deleteStyle" @click="deleteUsers(user)">
            {{ $t("sectionRegisters.delete") }}
          </button>
        </span>
      </tr>
    </table>
  </main>
</template>

<script>
import axios from "axios";
export default {
  name: "AreaRegister",
  data() {
    return {
      amountPage: "",
      activeRegister: true,
      activeEdit: true,
    };
  },
  mounted() {
    this.$store.dispatch("groupUsers/totalGroupUsers");
  },
  methods: {
    deleteUsers(user) {
      const { stateGroupUsers } = this;

      let auth = localStorage.getItem("userKey");
      let authStr = JSON.parse(auth);

      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/grupoUsuario/remover/${user.id}`,
          true,
          {
            headers: { Authorization: `Bearer ${authStr}` },
          }
        )
        .then(() => console.log("Deu tudo certo."))
        .catch(() => console.log("Ocorreu um erro. " + user.id));

      const findUser = stateGroupUsers.find((element) => element.id == user.id);
      const indexUser = stateGroupUsers.indexOf(findUser);

      stateGroupUsers.splice(indexUser, 1);
    },

    viewPages() {
      if (this.amountPage != "" && this.amountPage !== " ") {
        const pageGroupUsers = this.stateGroupUsers.slice(0, this.amountPage);
        this.amountPage = "";
        this.$store.commit("groupUsers/SET_GROUP_USERS", pageGroupUsers);
      }
    },
  },

  computed: {
    stateGroupUsers() {
      const { groupUsers } = this.$store.state.groupUsers;

      return groupUsers;
    },
  },
};
</script>

<style scoped>
.content-btn-addReturn {
  display: flex;
}

.btn-return {
  padding: 2px;
  width: 150px;
  margin-left: 10px;
  border: 1.5px gray solid;
  border-radius: 5px;
}

.userGroup-btn-add {
  padding: 2px;
  width: 150px;
  margin-left: 910px;
  border: 1.5px gray solid;
  border-radius: 5px;
}

/* .pbmViewSipro {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  } */

/* .content-searchPbm {
    padding: 10px;
     border: 1px red solid; 
  }  */

/* .content-searchPbm span {
    font-weight: bold;
    font-size: 1.2em;
  } */

/* .content-searchPbm input {
    margin-left: 5px;
    padding: 2px;
  } */

/* .content-searchPbm button {
    margin-left: 4px;
    padding: 2.5px;
    border: 1.5px gray solid;
    border-radius: 5px;
  } */

.content-tableUserGroup {
  border: 1px black solid;
}

.content-tableUserGroup .header-table {
  font-weight: bold;
  font-size: 1.1em;
  border: 2px black solid;
}

.content-tableUserGroup .items-table {
  /* margin: 50px; */
  border: 0.8px gray solid;
}

.content-tableUserGroup .items-table:hover {
  background-color: gray;
  color: black;
  font-weight: bold;
}

.content-btn-userGroupEditOrDelete .editStyle {
  margin: 3px;
  padding: 1px;
  color: black;
  border: 1.5px black solid;
  border-radius: 5px;
}

.content-btn-userGroupEditOrDelete .deleteStyle {
  margin-left: 3px;
  padding: 1px;
  color: black;
  background-color: red;
  border: 1px black solid;
  border-radius: 5px;
}
</style>
