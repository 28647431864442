<template>
  <div>
    <AreaRegister />
  </div>
</template>

<script>
  import AreaRegister from '@/components/cadastros/AreaRegister.vue';

  export default {
    components: {
      AreaRegister,
    }
  }
</script>
